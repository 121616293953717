<template>
  <!-- Need to add height inherit because Vue 2 don't support multiple root ele -->
  <div style="height: inherit">
    <modal-qr
      ref="refModalQr"
    />
    <modal-multimedia
      ref="refModalMultimedia"
      :data-info="dataInfo"
    />
    <div
      class="body-content-overlay"
      :class="{'show': mqShallShowLeftSidebar}"
      @click="mqShallShowLeftSidebar = false"
    />

    <!-- Email List -->
    <div class="email-app-list">

      <!-- App Searchbar Header -->
      <div class="app-fixed-search d-flex align-items-center">

        <!-- Toggler -->
        <div class="sidebar-toggle d-block d-lg-none ml-1">
          <feather-icon
            icon="MenuIcon"
            size="21"
            class="cursor-pointer"
            @click="mqShallShowLeftSidebar = true"
          />
        </div>

        <!-- Searchbar -->
        <div class="d-flex align-content-center justify-content-between w-100">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon
                icon="SearchIcon"
                class="text-muted"
              />
            </b-input-group-prepend>
            <b-form-input
              :value="searchQuery"
              placeholder="Buscar por razón social, ruc y otros datos; recuerde pulsar ENTER"
              @input="updateRouteQuery"
            />
          </b-input-group>
        </div>
      </div>

      <div
        v-if="isBusy"
        class="text-center text-primary my-2"
      >
        <b-spinner
          variant="primary"
          class="align-middle"
        />
        <strong class="ml-1">Cargando...</strong>
      </div>

      <!-- Emails List -->
      <vue-perfect-scrollbar
        :settings="perfectScrollbarSettings"
        class="email-user-list scroll-area"
      >
        <ul class="email-media-list">
          <b-media
            v-for="empresa in empresas"
            :key="empresa.idDirectorio"
            tag="li"
            no-body
            class="mail-read"
          >

            <!-- <b-media-aside class="media-left mr-50">
              <b-avatar
                rounded
                class="avatar"
                size="70"
                variant="primary"
                :src="empresa.multimedia.length > 0 ? `${pathMultimedia}/byUrl?isthumb=true&url=${empresa.multimedia[0].url}` : '0'"
              />
            </b-media-aside> -->

            <b-media-body>
              <div class="mail-details">
                <div class="mail-items">
                  <h5 class="mb-25">
                    {{ empresa.nombreComercial }}
                  </h5>
                  <b-badge
                    variant="light-secondary"
                    class="mr-50"
                  >
                    {{ empresa.destino.nombre }}
                  </b-badge>
                  <b-badge
                    variant="light-secondary"
                    class="mr-50"
                  >
                    {{ empresa.tipoServicioTuristico.labelFrontend }}
                  </b-badge>
                </div>
                <div class="mail-meta-item">
                  <b-button-toolbar justify>
                    <b-button-group size="sm">
                      <b-button
                        v-b-tooltip.hover.top="'Editar'"
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="primary"
                        @click="editEmpresa(empresa)"
                      >
                        <feather-icon icon="EditIcon" />
                      </b-button>
                      <b-button
                        v-b-tooltip.hover.top="'Código QR'"
                        :variant="empresa.qrCode !== null ? 'info' : 'secondary'"
                        class="btn-wishlist remove-wishlist"
                        @click="getModalQr(empresa)"
                      >
                        <feather-icon icon="GridIcon" />
                      </b-button>
                      <!-- <b-button
                        v-b-tooltip.hover.top="'Logo de la Empresa'"
                        variant="warning"
                        class="btn-wishlist remove-wishlist"
                        @click="imagesRegister(empresa)"
                      >
                        <feather-icon icon="ImageIcon" />
                      </b-button> -->
                      <b-button
                        v-b-tooltip.hover.top="'Eliminar'"
                        variant="danger"
                        class="btn-cart move-cart"
                        @click="deleteRegister(empresa)"
                      >
                        <feather-icon
                          icon="Trash2Icon"
                          class="mr-50"
                        />
                      </b-button>
                    </b-button-group>
                  </b-button-toolbar>
                </div>
              </div>

              <div class="mail-message">
                <span
                  v-if="empresa.urlSlug != null"
                  class="mail-date align-middle"
                >
                  <feather-icon
                    icon="StarIcon"
                    size="14"
                    :class="{ 'text-warning fill-current': empresa.destacado }"
                  />
                  Publicado
                </span>
                <span
                  v-else
                  class="mail-date align-middle"
                >
                  <feather-icon
                    icon="StarIcon"
                    size="14"
                    :class="{ 'text-warning fill-current': empresa.destacado }"
                  />
                  Registrado
                </span>
                <span class="mail-date align-middle">
                  <feather-icon
                    icon="PhoneCallIcon"
                    size="14"
                    class="text-secondary"
                  />
                  {{ empresa.telefono }}
                </span>
                <span class="mail-date align-middle">
                  <feather-icon
                    icon="MailIcon"
                    size="14"
                    class="text-secondary"
                  />
                  {{ empresa.email }}
                </span>
              </div>
            </b-media-body>
          </b-media>
        </ul>
      </vue-perfect-scrollbar>

      <div class="app-action">
        <div class="action-left">
          <span class="text-muted">Mostrando {{ from }} a {{ to }} de {{ of }} registros</span>
        </div>
        <b-pagination
          v-model="page"
          :total-rows="totalItems"
          :per-page="perPage"
          first-number
          align="center"
          last-number
          prev-class="prev-item"
          next-class="next-item"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </div>
    </div>

    <!-- Email View/Detail -->
    <empresa-view
      ref="refEmpresaView"
      :class="{'show': showEmailDetails}"
      :data-edit="dataEdit"
      :type-form="typeForm"
      @close-email-view="showEmailDetails = false"
      @refetch-data="refetchData"
      @error-data="showNotify"
    />

    <!-- Sidebar -->
    <portal to="content-renderer-sidebar-left">
      <empresa-left-sidebar
        :tipos-empresa="tiposEmpresa"
        :destinos-turisticos="destinosTuristicos"
        :shall-show-email-compose-modal.sync="shallShowEmailComposeModal"
        :show-email-details="showEmailDetails"
        :class="{'show': mqShallShowLeftSidebar}"
        @close-left-sidebar="mqShallShowLeftSidebar = false"
        @open-new-register="newEmpresa"
        @apply-filter="applyFilter"
      />
    </portal>

    <!-- Compose Email Modal -->
    <empresa-compose v-model="shallShowEmailComposeModal" />
  </div>
</template>

<script>
import Vue from 'vue'
import store from '@/store'
import {
  ref, onUnmounted, computed, watch,
  // ref, watch, computed, onUnmounted,
} from '@vue/composition-api'
import {
  BFormInput, BInputGroup, BInputGroupPrepend, BButton, VBTooltip, BMedia, /* BMediaAside, */ BMediaBody, /* BAvatar, */ BButtonToolbar, BButtonGroup, BBadge, BPagination, BSpinner,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'
import { filterTags, formatDateToMonthShort } from '@core/utils/filter'
import { useRouter } from '@core/utils/utils'
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'
import { useNotify } from '@/helpers/toast'
import config from '@/services/config'
import EmpresaLeftSidebar from './EmpresaLeftSidebar.vue'
import EmpresaView from './EmpresaView.vue'
import empresaStoreModule from './empresaStoreModule'
import useEmpresa from './useEmpresa'
import EmpresaCompose from './EmpresaCompose.vue'
import ModalMultimedia from '../../../components/multimedia/ModalMultimedia.vue'
import ModalQr from '../code_qr/ModalQr.vue'

export default {
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  components: {
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BMedia,
    // BMediaAside,
    BMediaBody,
    // BAvatar,
    BButtonToolbar,
    BButtonGroup,
    BButton,
    BBadge,
    BPagination,
    BSpinner,

    // 3rd Party
    VuePerfectScrollbar,

    // App SFC
    EmpresaLeftSidebar,
    EmpresaView,
    EmpresaCompose,
    ModalMultimedia,
    ModalQr,
  },
  setup(props, context) {
    const { notify } = useNotify(context)
    const EMAIL_APP_STORE_MODULE_NAME = 'app-email'
    const tiposEmpresa = ref([])
    const destinosTuristicos = ref([])
    const empresas = ref([])
    const { pathRepo, contextBO } = config
    const pathMultimedia = ref(`${pathRepo}${contextBO}/api/v1/multimedia`)
    const dataEdit = ref({})
    const dataInfo = ref({})
    const typeForm = ref('')
    const refModalMultimedia = ref(null)
    const refModalQr = ref(null)
    const refEmpresaView = ref(null)
    const totalRows = ref(0)
    const totalItems = ref(0)
    const totalPages = ref(0)
    const perPage = ref(10)
    const page = ref(1)
    const from = ref(0)
    const to = ref(0)
    const of = ref(0)
    const isBusy = ref(false)
    const pageOptions = ref([10, 20, 50])
    const showEmailDetails = ref(false)
    const ftidTipoDirectorio = ref('-1')
    const ftidDestinoTuristico = ref('-1')

    const showNotify = data => {
      notify(data.title, data.message, data.type)
    }

    const getTiposEmpresas = async () => {
      await store.dispatch('catalogo/TIPO_SERVICIO_TURISTICO_FIND_ALL', {
        query: '',
        page: 1,
        limit: -1,
        sortBy: 'idTipoServicioTuristico%7CASC',
      })
        .then(response => {
          if (response) {
            tiposEmpresa.value = response.items
          }
        })
    }

    const getDestiposTuristicos = async () => {
      await store.dispatch('catalogo/DESTINO_FIND_ALL', {
        query: '',
        page: 1,
        limit: -1,
        sortBy: 'idDestinoTuristico%7CASC',
      })
        .then(response => {
          if (response) {
            destinosTuristicos.value = response.items
          }
        })
    }

    const getModalQr = item => {
      refModalQr.value.idSeccion = item.idDirectorio
      refModalQr.value.idDestino = item.destino.idDestinoTuristico
      refModalQr.value.seccion = 'Directorio'
      refModalQr.value.srcQR = item.qrCode
      refModalQr.value.openModal()
    }

    // Register module
    if (!store.hasModule(EMAIL_APP_STORE_MODULE_NAME)) store.registerModule(EMAIL_APP_STORE_MODULE_NAME, empresaStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(EMAIL_APP_STORE_MODULE_NAME)) store.unregisterModule(EMAIL_APP_STORE_MODULE_NAME)
    })

    const { route, router } = useRouter()
    const { resolveLabelColor } = useEmpresa()

    // Route Params
    const routeParams = computed(() => route.value.params)
    watch(routeParams, () => {
      // eslint-disable-next-line no-use-before-define
      getEmpresas()
    })

    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }

    // Search Query
    const routeQuery = computed(() => route.value.query.q)
    const searchQuery = ref(routeQuery.value)
    watch(routeQuery, val => {
      searchQuery.value = val
    })
    // eslint-disable-next-line no-use-before-define
    watch(searchQuery, () => getEmpresas())
    const updateRouteQuery = val => {
      const currentRouteQuery = JSON.parse(JSON.stringify(route.value.query))

      if (val) currentRouteQuery.q = val
      else delete currentRouteQuery.q

      router.replace({ name: route.name, query: currentRouteQuery })
    }

    const getEmpresas = async () => {
      isBusy.value = true
      await store.dispatch('plataforma/EMPRESA_FIND_ALL', {
        query: searchQuery.value !== undefined ? searchQuery.value : '',
        page: page.value,
        limit: perPage.value,
        sortBy: 'idDirectorio%7CASC',
        idDestino: ftidDestinoTuristico.value,
        idTipoDirectorio: ftidTipoDirectorio.value,
        urlSlugDirectorio: router.currentRoute.params.urlSlug || '',
      })
        .then(response => {
          if (response) {
            empresas.value = response.items
            totalRows.value = response.totalRows
            totalItems.value = response.totalItems
            totalPages.value = response.totalPage
            from.value = perPage.value * (page.value - 1) + (totalRows.value ? 1 : 0)
            to.value = perPage.value * (page.value - 1) + totalRows.value
            of.value = totalItems.value
          }
        })
      isBusy.value = false
    }

    const applyFilter = filters => {
      if (filters.value.idsTST !== null && filters.value.idsTST.length > 0) {
        ftidTipoDirectorio.value = filters.value.idsTST.join()
      } else {
        ftidTipoDirectorio.value = '-1'
      }
      if (filters.value.idsDT !== null && filters.value.idsDT.length > 0) {
        ftidDestinoTuristico.value = filters.value.idsDT.join()
      } else {
        ftidDestinoTuristico.value = '-1'
      }
      getEmpresas()
    }

    const refetchData = response => {
      let message = 'El proceso se ha ejecutado satisfactoriamente.'
      if (response.message) {
        message = response.message
      }
      showNotify({ title: 'Operación Exitosa', message, type: 'success' })
      getEmpresas()
    }

    watch([page], () => {
      getEmpresas()
    })

    getDestiposTuristicos()
    getTiposEmpresas()
    getEmpresas()

    const newEmpresa = () => {
      refEmpresaView.value.typeForm = 'new'
      refEmpresaView.value.loadData()
      showEmailDetails.value = true
    }

    const editEmpresa = item => {
      dataEdit.value = item
      refEmpresaView.value.typeForm = 'edit'
      refEmpresaView.value.loadData()
      showEmailDetails.value = true
    }

    const deleteRegister = item => {
      Vue.swal({
        title: 'Está seguro?',
        text: 'Va eliminar un registro!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sí',
        cancelButtonText: 'No',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          store
            .dispatch('plataforma/EMPRESA_DELETE', {
              id: item.idDirectorio,
            })
            .then(() => {
              notify('Operación Exitosa', 'El proceso se ha ejecutado satisfactoriamente.', 'success')
              getEmpresas()
            })
            .catch(error => {
              let errorMessage = 'Intentelo en otro momento.'
              if (error.response) {
                errorMessage = error.response.data.message
              }
              notify('Ha ocurrido un error', errorMessage, 'danger')
            })
        }
      })
    }

    const imagesRegister = item => {
      dataInfo.value.alertMsgTitle = 'Imágenes registradas como logo de la empresa.'
      dataInfo.value.alertMsgSubTitle = 'Dimensiones recomendadas 1000x667.'
      dataInfo.value.alertIcon = 'InfoIcon'
      refModalMultimedia.value.idSeccion = item.idDirectorio
      refModalMultimedia.value.idDestinoTuristico = item.destino.idDestinoTuristico
      refModalMultimedia.value.seccion = 'DIRECTORIO'
      refModalMultimedia.value.orientacion = 'H'
      refModalMultimedia.value.loadRegisters()
      refModalMultimedia.value.openModal()
      // modalshow.value = true
    }

    // * If someone clicks on filter while viewing detail => Close the email detail view
    watch(routeParams, () => {
      showEmailDetails.value = false
    })

    // Compose
    const shallShowEmailComposeModal = ref(false)

    // Left Sidebar Responsiveness
    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()

    return {
      refModalQr,
      refModalMultimedia,
      refEmpresaView,
      tiposEmpresa,
      destinosTuristicos,
      empresas,
      pathMultimedia,
      newEmpresa,
      editEmpresa,
      deleteRegister,
      imagesRegister,
      getModalQr,
      dataEdit,
      dataInfo,
      typeForm,
      // UI
      perfectScrollbarSettings,
      // Email Details
      showEmailDetails,
      // Search Query
      searchQuery,
      updateRouteQuery,

      // UI Filters
      filterTags,
      formatDateToMonthShort,

      // useEmail
      resolveLabelColor,

      // Compose
      shallShowEmailComposeModal,

      // Left Sidebar Responsiveness
      mqShallShowLeftSidebar,

      totalItems,
      perPage,
      page,
      totalPages,
      totalRows,
      from,
      of,
      to,
      isBusy,
      pageOptions,
      showNotify,
      refetchData,
      applyFilter,
    }
  },
}
</script>

<style lang="scss" scoped>

</style>

<style lang="scss">
@import "~@core/scss/base/pages/app-email.scss";

.per-page-selector {
  width: 90px;
}

.email-application .content-area-wrapper .email-user-list .email-media-list li .avatar img {
  width: 100% !important;
  height: 100% !important;
}

.email-application .content-area-wrapper .email-user-list .email-media-list li .avatar {
  margin-bottom: 0px !important;
}

.email-application .app-action {
    padding: 0.6rem 1.5rem;
}
</style>
