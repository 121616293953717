<template>
  <div class="email-app-details">
    <!-- Email Header -->
    <div class="email-detail-header">
      <!-- Header: Left -->
      <div class="email-header-left d-flex align-items-center">
        <span class="go-back mr-1">
          <feather-icon
            :icon="$store.state.appConfig.isRTL ? 'ChevronRightIcon' : 'ChevronLeftIcon'"
            size="20"
            class="align-bottom"
            @click="$emit('close-email-view')"
          />
        </span>
        <h4 class="email-subject mb-0">
          {{ titleForm }}
        </h4>
      </div>

      <!-- Header: Right -->
      <div class="email-header-right ml-2 pl-1">
        <b-button-group size="sm">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="primary"
            @click="saveForm"
          >
            <feather-icon
              class="mr-50"
              icon="SaveIcon"
            />
            <span class="align-middle">Guardar</span>
          </b-button>
        </b-button-group>
      </div>
    </div>
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="email-user-list scroll-area"
    >
      <b-overlay
        :show="show"
        rounded="sm"
      >
        <b-card id="seccion-content">
          <b-card-body class="p-0">
            <b-form class="pl-0 pr-0">
              <h6 class="text-dark">
                <feather-icon icon="ClipboardIcon" />
                <span class="align-middle ml-25">Ficha Básica</span>
              </h6>

              <hr
                style="border-top: 2px solid #161d31;margin-bottom: 1rem !important;margin-top: 0 !important;"
              >
              <b-row>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    label="Destino Turístico"
                    label-for="destino"
                  >
                    <v-select
                      id="destino"
                      v-model="destinoSel"
                      label="nombre"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="destinos"
                      :clearable="false"
                    />
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    label="Tipo Empresa"
                    label-for="tipoEmpresa"
                  >
                    <v-select
                      id="tipoEmpresa"
                      v-model="tipoEmpresaSel"
                      label="labelFrontend"
                      :clearable="false"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="tiposEmpresas"
                      @input="changeTipoEmpresa"
                    />
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    label="Ruc"
                    label-for="ruc"
                  >
                    <b-form-input
                      id="ruc"
                      v-model="dataRegister.ruc"
                    />
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    label="Razón Social"
                    label-for="razonSocial"
                  >
                    <b-form-input
                      id="razonSocial"
                      v-model="dataRegister.razonSocial"
                    />
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    label="Nombre Comercial"
                    label-for="nombreComercial"
                  >
                    <b-form-input
                      id="nombreComercial"
                      v-model="dataRegister.nombreComercial"
                    />
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    label="Representante Legal"
                    label-for="representanteLegal"
                  >
                    <b-form-input
                      id="representanteLegal"
                      v-model="dataRegister.representanteLegal"
                    />
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col
                  cols="12"
                  md="12"
                >
                  <b-form-group
                    label="Descripción"
                    label-for="descripcion"
                  >
                    <quill-editor
                      id="descripcion"
                      v-model="dataRegister.descripcion"
                      :options="editorOption"
                      class="border-bottom-0"
                    />
                  </b-form-group>
                </b-col>
              </b-row>

              <h6 class="text-dark">
                <feather-icon icon="MapPinIcon" />
                <span class="align-middle ml-25">Datos de Ubicación</span>
              </h6>

              <hr
                style="border-top: 2px solid #161d31;margin-bottom: 1rem !important;margin-top: 0 !important;"
              >

              <b-row>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    label="Departamento"
                    label-for="dpto"
                  >
                    <v-select
                      v-model="dptoSel"
                      :reduce="m => m.idUbigeo"
                      label="descripcion"
                      :clearable="false"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="dptos"
                      @input="getUbigeos('prov', dptoSel.slice(0, 2))"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    label="Provincia"
                    label-for="prov"
                  >
                    <v-select
                      v-model="provSel"
                      :reduce="m => m.idUbigeo"
                      label="descripcion"
                      :clearable="false"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="provs"
                      @input="getUbigeos('dist', provSel.slice(0, 4))"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    label="Distrito"
                    label-for="dist"
                  >
                    <v-select
                      v-model="distSel"
                      :reduce="m => m.idUbigeo"
                      label="descripcion"
                      :clearable="false"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="dists"
                    />
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col
                  cols="12"
                  md="8"
                >
                  <b-form-group
                    label="Dirección"
                    label-for="direccion"
                  >
                    <b-form-input
                      id="direccion"
                      v-model="dataRegister.direccion"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="2"
                >
                  <b-form-group
                    label="Latitud"
                    label-for="latitud"
                  >
                    <b-form-input
                      id="latitud"
                      v-model="dataRegister.latitud"
                      type="number"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="2"
                >
                  <b-form-group
                    label="Longitud"
                    label-for="longitud"
                  >
                    <b-form-input
                      id="longitud"
                      v-model="dataRegister.longitud"
                      type="number"
                    />
                  </b-form-group>
                </b-col>
              </b-row>

              <h6 class="text-dark">
                <feather-icon icon="PhoneOutgoingIcon" />
                <span class="align-middle ml-25">Datos de Contacto y Canales Digitales</span>
              </h6>

              <hr
                style="border-top: 2px solid #161d31;margin-bottom: 1rem !important;margin-top: 0 !important;"
              >

              <b-row>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    label="Teléfono"
                    label-for="telefono"
                  >
                    <b-form-input
                      id="telefono"
                      v-model="dataRegister.telefono"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    label="Whatsapp"
                    label-for="celular"
                  >
                    <b-form-input
                      id="celular"
                      v-model="dataRegister.celular"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    label="Email"
                    label-for="email"
                  >
                    <b-form-input
                      id="email"
                      v-model="dataRegister.email"
                      type="email"
                    />
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    label="Página Web"
                    label-for="sitioWeb"
                  >
                    <b-form-input
                      id="sitioWeb"
                      v-model="dataRegister.sitioWeb"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    label="Facebook"
                    label-for="facebook"
                  >
                    <b-form-input
                      id="facebook"
                      v-model="dataRedesSociales.facebook"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    label="Youtube"
                    label-for="youtube"
                  >
                    <b-form-input
                      id="youtube"
                      v-model="dataRedesSociales.youtube"
                    />
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    label="Instagram"
                    label-for="instagram"
                  >
                    <b-form-input
                      id="instagram"
                      v-model="dataRedesSociales.instagram"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    label="Twitter"
                    label-for="twitter"
                  >
                    <b-form-input
                      id="twitter"
                      v-model="dataRedesSociales.twitter"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    label="TikTok"
                    label-for="tiktok"
                  >
                    <b-form-input
                      id="tiktok"
                      v-model="dataRedesSociales.tiktok"
                    />
                  </b-form-group>
                </b-col>
              </b-row>

              <h6 class="text-dark">
                <feather-icon icon="SettingsIcon" />
                <span class="align-middle ml-25">Configuración Personalizada</span>
              </h6>

              <hr
                style="border-top: 2px solid #161d31;margin-bottom: 1rem !important;margin-top: 0 !important;"
              >

              <b-row>
                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group
                    label="Habilitar Pasarela?"
                    label-for="hasShoppingCart"
                  >
                    <v-select
                      id="hasShoppingCart"
                      v-model="hasShoppingCartSel"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="hasShoppingCartOpts"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group
                    label="Habilitar Chatbot?"
                    label-for="hasChatBot"
                  >
                    <v-select
                      id="hasChatBot"
                      v-model="hasChatBotSel"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="hasChatBotOpts"
                    />
                  </b-form-group>
                </b-col>
              </b-row>

              <!-- AGENCIAS -->
              <div
                v-if="isAgencia"
              >
                <h6 class="text-dark">
                  <feather-icon icon="CheckSquareIcon" />
                  <span class="align-middle ml-25">Datos Adicionales por Tipo de Empresa</span>
                </h6>

                <hr
                  style="border-top: 2px solid #161d31;margin-bottom: 1rem !important;margin-top: 0 !important;"
                >

                <!-- <b-row>
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      label="Clase de Agencia"
                      label-for="claseAgencia"
                    >
                      <v-select
                        id="claseAgencia"
                        v-model="claseAgenciaSel"
                        label="nombre"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="clasesAgencia"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      label="Tipo de Turismo"
                      label-for="tipoTurismo"
                    >
                      <v-select
                        id="tipoTurismo"
                        v-model="tipoTurismoSel"
                        label="nombre"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="tiposTurismo"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      label="Modalidad de Turismo"
                      label-for="modalidadTurismo"
                    >
                      <v-select
                        id="tipoTurismo"
                        v-model="modalidadTurismoSel"
                        label="descripcion"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="modalidadesTurismo"
                      />
                    </b-form-group>
                  </b-col>
                </b-row> -->

                <b-row>
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group
                      label="Otros Equipos"
                      label-for="otrosEquipos"
                    >
                      <b-form-input
                        id="otrosEquipos"
                        v-model="dataAgencia.otrosEquipos"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group
                      label="Asociaciones a la que Pertenece"
                      label-for="asociacionPertenece"
                    >
                      <b-form-input
                        id="asociacionPertenece"
                        v-model="dataAgencia.asociacionPertenece"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group
                      label="Clasificación"
                      label-for="califacionCalidad"
                    >
                      <v-select
                        id="califacionCalidad"
                        v-model="dataAgencia.califacionCalidad"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="clasificacionOpts"
                        multiple
                      />
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group
                      label="Vehículo Propio"
                      label-for="vehiculoPropio"
                    >
                      <v-select
                        id="vehiculoPropio"
                        v-model="dataAgencia.vehiculoPropio"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="vehiculoPropioOpts"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </div>
              <!-- RESTAURANTES -->
              <div
                v-if="isRestaurante"
              >
                <h6 class="text-dark">
                  <feather-icon icon="CheckSquareIcon" />
                  <span class="align-middle ml-25">Datos Adicionales por Tipo de Empresa</span>
                </h6>

                <hr
                  style="border-top: 2px solid #161d31;margin-bottom: 1rem !important;margin-top: 0 !important;"
                >

                <!-- <b-row>
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group
                      label="Categoria Restaurante"
                      label-for="categoriaRestaurante"
                    >
                      <v-select
                        id="categoriaRestaurante"
                        v-model="categoriaRestauranteSel"
                        label="nombre"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="categoriasRestaurante"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group
                      label="Tipos de Comida"
                      label-for="tipoComida"
                    >
                      <v-select
                        id="tipoComida"
                        v-model="tipoComidaSel"
                        label="nombre"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="tiposComida"
                      />
                    </b-form-group>
                  </b-col>
                </b-row> -->

                <b-row>
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group
                      label="Número de Mesas"
                      label-for="nroMesas"
                    >
                      <b-form-input
                        id="nroMesas"
                        v-model="dataRestaurante.nroMesas"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group
                      label="Número de Sillas"
                      label-for="nroSillas"
                    >
                      <b-form-input
                        id="nroSillas"
                        v-model="dataRestaurante.nroSillas"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </div>
              <!-- HOSPEDAJES -->
              <div
                v-if="isHospedaje"
              >
                <h6 class="text-dark">
                  <feather-icon icon="CheckSquareIcon" />
                  <span class="align-middle ml-25">Datos Adicionales por Tipo de Empresa</span>
                </h6>

                <hr
                  style="border-top: 2px solid #161d31;margin-bottom: 1rem !important;margin-top: 0 !important;"
                >

                <!-- <b-row>
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      label="Clase de Hospedaje"
                      label-for="claseHospedaje"
                    >
                      <v-select
                        id="claseHospedaje"
                        v-model="claseHospedajeSel"
                        label="descripcion"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="clasesHospedaje"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      label="Clase de Hospedaje"
                      label-for="claseHospedaje"
                    >
                      <b-form-rating
                        id="rating-inline"
                        inline
                        no-border
                        variant="warning"
                        value="4"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      label="Tipos de Habitación"
                      label-for="tipoHabitacion"
                    >
                      <v-select
                        id="tipoHabitacion"
                        v-model="tipoHabitacionSel"
                        label="descripcion"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="tiposHabitacion"
                      />
                    </b-form-group>
                  </b-col>
                </b-row> -->

                <b-row>
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      label="Número de Pisos"
                      label-for="nroPisos"
                    >
                      <b-form-input
                        id="nroPisos"
                        v-model="dataHospedaje.nroPisos"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      label="Número de Habitaciones"
                      label-for="nroHabitaciones"
                    >
                      <b-form-input
                        id="nroHabitaciones"
                        v-model="dataHospedaje.nroHabitaciones"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      label="Número de Camas"
                      label-for="nroCamas"
                    >
                      <b-form-input
                        id="nroCamas"
                        v-model="dataHospedaje.nroCamas"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </div>
            </b-form>
          </b-card-body>
        </b-card>
      </b-overlay>
    </vue-perfect-scrollbar>
  </div>
</template>

<script>
import store from '@/store'
import {
  BButtonGroup, BButton, BRow, BCol, BCard, BCardBody, BForm, BFormGroup, BFormInput, BOverlay, /* BFormRating, */
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'
import { ref } from '@vue/composition-api'
import vSelect from 'vue-select'
import { quillEditor } from 'vue-quill-editor'

export default {
  directives: {
    Ripple,
  },
  components: {

    // BSV
    BButtonGroup,
    BButton,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BForm,
    BFormGroup,
    BFormInput,
    BOverlay,
    /* BFormRating, */

    vSelect,
    quillEditor,
    VuePerfectScrollbar,
  },
  props: {
    dataEdit: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const titleForm = ref('Agregar Empresa')
    const dataRegister = ref({})
    const dataHospedaje = ref({})
    const dataRestaurante = ref({})
    const dataAgencia = ref({})
    const isBusy = ref(false)
    const destinoSel = ref({})
    const destinos = ref([])
    const tipoEmpresaSel = ref({})
    const tiposEmpresas = ref([])
    const dptoSel = ref('220000')
    const provSel = ref(null)
    const distSel = ref(null)
    const dptos = ref([])
    const provs = ref([])
    const dists = ref([])
    const show = ref(false)
    const typeForm = ref('')
    const isHospedaje = ref(false)
    const isRestaurante = ref(false)
    const isAgencia = ref(false)
    const dataRedesSociales = ref({
      facebook: '',
      youtube: '',
      twitter: '',
      instagram: '',
    })
    const hasChatBotOpts = ref(['SI', 'NO'])
    const hasShoppingCartOpts = ref(['SI', 'NO'])
    const hasChatBotSel = ref('NO')
    const hasShoppingCartSel = ref('NO')

    const clasesHospedaje = ref([])
    const claseHospedajeSel = ref({})
    const tiposHabitacion = ref([])
    const tipoHabitacionSel = ref({})

    const categoriasRestaurante = ref([])
    const categoriaRestauranteSel = ref([])
    const tiposComida = ref([])
    const tipoComidaSel = ref({})

    const clasificacionOpts = ref(['Minorista', 'Mayorista', 'Operador de Turismo'])
    const vehiculoPropioOpts = ref(['SI', 'NO'])
    const clasesAgencia = ref([])
    const claseAgenciaSel = ref({})
    const tiposTurismo = ref([])
    const tipoTurismoSel = ref({})
    const modalidadesTurismo = ref({})
    const modalidadTurismoSel = ref({})

    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }

    const toolbarOptions = [
      ['bold', 'italic', 'underline', 'strike'],
      [{ align: [] }],
      ['blockquote'],
      [{ header: 1 }, { header: 2 }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['link', 'image'],
      // [{ script: 'sub' }, { script: 'super' }],
      [{ indent: '-1' }, { indent: '+1' }],
      [{ direction: 'rtl' }],
      // [{ size: ['small', false, 'large', 'huge'] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ color: [] }, { background: [] }],
      // [{ font: [] }],
      // ['clean'],
    ]

    const editorOption = {
      modules: {
        toolbar: toolbarOptions,
      },
      placeholder: 'Ingrese descripción',
    }

    const getDestinos = async () => {
      await store
        .dispatch('catalogo/DESTINO_FIND_ALL', {
          query: '',
          page: -1,
          limit: -1,
          sortBy: '',
        })
        .then(response => {
          if (response.items) {
            destinos.value = response.items
          }
        })
        .catch(error => {
          emit('error-data', error)
        })
    }

    const getTiposEmpresas = async () => {
      await store.dispatch('catalogo/TIPO_SERVICIO_TURISTICO_FIND_ALL', {
        query: '',
        page: 1,
        limit: -1,
        sortBy: 'idTipoServicioTuristico%7CASC',
      })
        .then(response => {
          if (response) {
            tiposEmpresas.value = response.items
          }
        })
    }

    const getClasificaciones = async seccion => {
      await store.dispatch('catalogo/CLASIFICACION_FIND_ALL_BY_SECCION', {
        seccion,
      })
        .then(response => {
          if (seccion === 'CATEGORIAS_RESTAURANTES') {
            categoriasRestaurante.value = response.data
          }
          if (seccion === 'TIPOS_COMIDAS') {
            tiposComida.value = response.data
          }
          if (seccion === 'CLASES_AGENCIAS') {
            clasesAgencia.value = response.data
          }
          if (seccion === 'TIPO_TURISMO') {
            tiposTurismo.value = response.data
          }
        })
    }

    const getUbigeos = async (type, codUbigeo) => {
      await store
        .dispatch('catalogo/UBIGEO_FIND_ALL', {
          get: type,
          codUbigeo,
        })
        .then(response => {
          if (type === 'dpto') {
            dptos.value = response
            provSel.value = null
            distSel.value = null
            provs.value = []
            dists.value = []
          }
          if (type === 'prov') {
            provs.value = response
            provSel.value = null
            distSel.value = null
            dists.value = []
          }
          if (type === 'dist') {
            dists.value = response
          }
        })
        .catch(error => {
          emit('error-data', error)
        })
    }

    const getClasesHospedaje = async () => {
      await store.dispatch('catalogo/CLASE_HOSPEDAJE_FIND_ALL', {
        query: '',
        page: 1,
        limit: -1,
        sortBy: 'idClaseHospedaje%7CASC',
      })
        .then(response => {
          if (response) {
            clasesHospedaje.value = response.items
          }
        })
    }

    const getModalidadesTurismo = async () => {
      await store.dispatch('catalogo/MODALIDAD_TURISMO_FIND_ALL', {
        query: '',
        page: 1,
        limit: -1,
        sortBy: 'idModalidadTurismo%7CASC',
      })
        .then(response => {
          if (response) {
            modalidadesTurismo.value = response.items
          }
        })
    }

    const getTiposHabitacion = async () => {
      await store.dispatch('catalogo/TIPO_HABITACION_FIND_ALL', {
        query: '',
        page: 1,
        limit: -1,
        sortBy: 'idTipoHabitacion%7CASC',
      })
        .then(response => {
          if (response) {
            tiposHabitacion.value = response.items
          }
        })
    }

    const getDataAdicional = async service => {
      let result
      await store.dispatch(service, {
        idDirectorio: dataRegister?.value.idDirectorio,
      })
        .then(response => {
          if (response) {
            result = response
          }
        })
      return result
    }

    const saveForm = async () => {
      if (destinoSel.value === null) {
        emit('error-data', { title: 'Ha ocurrido un error', message: 'Debe seleccionar Destino', type: 'danger' })
        return
      }

      if (tipoEmpresaSel.value === null) {
        emit('error-data', { title: 'Ha ocurrido un error', message: 'Debe seleccionar Tipo de Servicio Turístico', type: 'danger' })
        return
      }

      if (dataRegister.value.ruc === null || dataRegister.value.ruc?.length !== 11) {
        emit('error-data', { title: 'Ha ocurrido un error', message: 'Debe ingresar RUC de la empresa', type: 'danger' })
        return
      }

      if (dataRegister.value.razonSocial === undefined) {
        emit('error-data', { title: 'Ha ocurrido un error', message: 'Debe ingresar razón social de la empresa', type: 'danger' })
        return
      }

      if (dataRegister.value.nombreComercial === undefined) {
        emit('error-data', { title: 'Ha ocurrido un error', message: 'Debe ingresar nombre comercial de la empresa', type: 'danger' })
        return
      }

      if (dptoSel.value === null) {
        emit('error-data', { title: 'Ha ocurrido un error', message: 'Debe seleccionar departamento', type: 'danger' })
        return
      }

      if (provSel.value === null) {
        emit('error-data', { title: 'Ha ocurrido un error', message: 'Debe seleccionar provincia', type: 'danger' })
        return
      }

      if (distSel.value === null) {
        emit('error-data', { title: 'Ha ocurrido un error', message: 'Debe seleccionar distrito', type: 'danger' })
        return
      }

      dataRegister.value.destino = destinoSel.value
      dataRegister.value.tipoServicioTuristico = tipoEmpresaSel.value
      dataRegister.value.ubigeo = distSel.value
      dataRegister.value.redesSociales = `Facebook**${dataRedesSociales.value.facebook}###Youtube**${dataRedesSociales.value.youtube}###Twitter**${dataRedesSociales.value.twitter}###Instagram**${dataRedesSociales.value.instagram}###TikTok**${dataRedesSociales.value.tiktok}`
      dataRegister.value.hasChatBot = hasChatBotSel.value === 'SI'
      dataRegister.value.hasShoppingCart = hasShoppingCartSel === 'SI'

      let service = 'plataforma/EMPRESA_CREATE'
      if (typeForm.value === 'edit') {
        service = 'plataforma/EMPRESA_UPDATE'
      }
      await store.dispatch(service, dataRegister.value)
        .then(async response => {
          if (tipoEmpresaSel.value.idTipoServicioTuristico === 1) {
            dataAgencia.value.califacionCalidad = Array.isArray(dataAgencia.value.califacionCalidad) ? dataAgencia.value.califacionCalidad?.join(',') : dataAgencia.value.califacionCalidad
            dataAgencia.value.vehiculoPropio = dataAgencia.value.vehiculoPropio === 'SI'
            await store.dispatch('plataforma/EMPRESA_AGENCIA_CREATE_UPDATE_DATA_ADDITIONA', dataAgencia.value)
          }
          if (tipoEmpresaSel.value.idTipoServicioTuristico === 2) {
            await store.dispatch('plataforma/EMPRESA_RESTAURANTE_CREATE_UPDATE_DATA_ADDITIONA', dataRestaurante.value)
          }
          if (tipoEmpresaSel.value.idTipoServicioTuristico === 3) {
            await store.dispatch('plataforma/EMPRESA_HOSPEDAJE_CREATE_UPDATE_DATA_ADDITIONA', dataHospedaje.value)
          }

          emit('refetch-data', response)
          emit('close-email-view')
          // emit('update:is-toggle-sidebar', false)
        })
        .catch(error => {
          emit('error-data', error)
        })
    }

    const changeTipoEmpresa = async () => {
      isHospedaje.value = false
      isRestaurante.value = false
      isAgencia.value = false
      if (tipoEmpresaSel.value.idTipoServicioTuristico === 1) {
        await getClasificaciones('CLASES_AGENCIAS')
        await getClasificaciones('TIPO_TURISMO')
        await getModalidadesTurismo()
        isAgencia.value = true
        dataAgencia.value = {}
        if (typeForm.value === 'edit') {
          const dataBD = await getDataAdicional('plataforma/EMPRESA_AGENCIA_FIND_DATA_ADDITIONAL')
          dataAgencia.value = dataBD
          dataAgencia.value.vehiculoPropio = dataBD.vehiculoPropio ? 'SI' : 'NO'
          dataAgencia.value.califacionCalidad = dataBD.califacionCalidad.includes(',') ? dataBD.califacionCalidad?.split(',') : dataBD.califacionCalidad
        }
      }
      if (tipoEmpresaSel.value.idTipoServicioTuristico === 2) {
        await getClasificaciones('CATEGORIAS_RESTAURANTES')
        await getClasificaciones('TIPOS_COMIDAS')
        isRestaurante.value = true
        dataRestaurante.value = {}
        if (typeForm.value === 'edit') {
          const dataBD = await getDataAdicional('plataforma/EMPRESA_RESTAURANTE_FIND_DATA_ADDITIONAL')
          dataRestaurante.value = dataBD
        }
      }
      if (tipoEmpresaSel.value.idTipoServicioTuristico === 3) {
        await getClasesHospedaje()
        await getTiposHabitacion()
        isHospedaje.value = true
        dataHospedaje.value = {}
        if (typeForm.value === 'edit') {
          const dataBD = await getDataAdicional('plataforma/EMPRESA_HOSPEDAJE_FIND_DATA_ADDITIONAL')
          dataHospedaje.value = dataBD
        }
      }
    }

    const getRedesSociales = dataRS => {
      if (dataRS !== null && dataRS.length > 0) {
        if (dataRS.split('###').length > 1) {
          if (dataRS.split('###')[0]?.split('**')[1].length > 5) {
            // eslint-disable-next-line prefer-destructuring
            dataRedesSociales.value.facebook = dataRS.split('###')[0].split('**')[1]
          }
          if (dataRS.split('###')[1]?.split('**')[1].length > 5) {
            // eslint-disable-next-line prefer-destructuring
            dataRedesSociales.value.youtube = dataRS.split('###')[1].split('**')[1]
          }
          if (dataRS.split('###')[2]?.split('**')[1].length > 5) {
            // eslint-disable-next-line prefer-destructuring
            dataRedesSociales.value.twitter = dataRS.split('###')[2].split('**')[1]
          }
          if (dataRS.split('###')[3]?.split('**')[1].length > 5) {
            // eslint-disable-next-line prefer-destructuring
            dataRedesSociales.value.instagram = dataRS.split('###')[3].split('**')[1]
          }
          if (dataRS.split('###')[4]?.split('**')[1].length > 5) {
            // eslint-disable-next-line prefer-destructuring
            dataRedesSociales.value.tiktok = dataRS.split('###')[4].split('**')[1]
          }
        }
      } else {
        dataRedesSociales.value.facebook = ''
        dataRedesSociales.value.youtube = ''
        dataRedesSociales.value.twitter = ''
        dataRedesSociales.value.instagram = ''
        dataRedesSociales.value.tiktok = ''
      }
    }

    const resetForm = () => {
      dataRegister.value = {}
      dataRedesSociales.value = {
        facebook: '',
        youtube: '',
        twitter: '',
        instagram: '',
        tiktok: '',
      }
      destinoSel.value = {}
      tipoEmpresaSel.value = {}
    }

    const loadData = async () => {
      titleForm.value = typeForm.value === 'edit' ? 'Editar Empresa' : 'Agregar Empresa'
      show.value = true
      resetForm()
      await getDestinos()
      await getTiposEmpresas()
      await getUbigeos('dpto', '')
      if (typeForm.value === 'edit') {
        dataRegister.value = props.dataEdit
        destinoSel.value = dataRegister.value.destino
        tipoEmpresaSel.value = dataRegister.value.tipoServicioTuristico
        hasChatBotSel.value = dataRegister.value.hasChatBot ? 'SI' : 'NO'
        hasShoppingCartSel.value = dataRegister.value.hasShoppingCart ? 'SI' : 'NO'
        dptoSel.value = `${props.dataEdit.ubigeo.slice(0, 2)}0000`
        await getUbigeos('dpto', '')
        await getUbigeos('prov', props.dataEdit.ubigeo.slice(0, 2))
        provSel.value = `${props.dataEdit.ubigeo.slice(0, 4)}00`
        await getUbigeos('dist', props.dataEdit.ubigeo.slice(0, 4))
        distSel.value = props.dataEdit.ubigeo
        getRedesSociales(props.dataEdit.redesSociales)
        changeTipoEmpresa()
      } else {
        dataAgencia.value = {}
        dataHospedaje.value = {}
        dataRestaurante.value = {}
        isAgencia.value = false
        isRestaurante.value = false
        isHospedaje.value = false
        hasChatBotSel.value = 'NO'
        hasShoppingCartSel.value = 'NO'
        await getUbigeos('dpto', '')
        await getUbigeos('prov', dptoSel.value.slice(0, 2))
        dataRegister.value = {}
        destinoSel.value = null
        tipoEmpresaSel.value = null
        getRedesSociales('')
      }
      show.value = false
    }

    return {
      titleForm,
      isBusy,
      dataRegister,
      dataRedesSociales,
      dataHospedaje,
      dataRestaurante,
      dataAgencia,
      getDestinos,
      destinos,
      destinoSel,
      tipoEmpresaSel,
      tiposEmpresas,
      dptoSel,
      provSel,
      distSel,
      dptos,
      provs,
      dists,
      getUbigeos,
      editorOption,
      saveForm,
      perfectScrollbarSettings,
      show,
      loadData,
      typeForm,
      changeTipoEmpresa,
      isHospedaje,
      isRestaurante,
      isAgencia,
      clasesHospedaje,
      claseHospedajeSel,
      tipoHabitacionSel,
      tiposHabitacion,
      categoriaRestauranteSel,
      categoriasRestaurante,
      tipoComidaSel,
      tiposComida,
      clasesAgencia,
      claseAgenciaSel,
      tiposTurismo,
      tipoTurismoSel,
      modalidadesTurismo,
      modalidadTurismoSel,
      hasChatBotOpts,
      hasChatBotSel,
      hasShoppingCartOpts,
      hasShoppingCartSel,
      vehiculoPropioOpts,
      clasificacionOpts,
    }
  },
}
</script>

<style>

</style>
